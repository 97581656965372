import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { graphql } from "gatsby"

export default function Home({ data, location }) {
  const post = data.markdownRemark

  return (
    <Layout>
      <SEO
        title="FAQs"
        description="All the recipes from Andrew Johnson"
        keywords={[`recipes`, `baking`, `Andrew Johnson`]}
        pathname={location.pathname}
      />
      <main className="prose container mx-auto px-4">
        <article>
          <div className="text-gray-500 font-mono text-sm mb-4">UPDATED: 06/14/21</div>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </article>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    markdownRemark(fields: { slug: { eq: "/faqs/" } }) {
      html
    }
  }
`
